import React from 'react';
import {STYLES} from "../App";
import {Col, Container, Row} from "react-bootstrap";

const About = () => {
    return (
    <div>
        <Container className={STYLES.cards} fluid={'true'}>
            <h4>О нас</h4>
            Агентство <i>flippium</i> занимается инвестициями в редевелопмент жилых помещений (флиппингом) в городе Екатеринбург. Мы покупаем квартиры по низу рынка, делаем привлекательный ремонт и продаем их по верху рынка.
            Цикл одного проекта 3-4 месяца, средняя рентабельность (ROI) 50%. Приглашаем инвестировать и получать прибыль вместе с нами - смотрите примеры наших проектов в разделе <a href={'/cases/'}>Кейсы</a>
        </Container>
        <div >
            <Container className={STYLES.cards} fluid={'true'}>
                <Row xs={'1'} sm={'2'}>
                    <Col>
                    <h4>Предлагаемая схема взаимодействия</h4>
                    <ul>
                        <li>
                            Вы предварительно <a href={'/contacts'}>связываетесь</a> с нами и наше агентство, используя собственное программное обеспечение, подбирает возможные варианты для инвестирования
                        </li>
                        <li>После согласования квартира приобретается и оформляется на инвестора (максимальная доходность достигается при использовании «ипотечного плеча»)</li>
                        <li>Между инвестором и нашим агентством заключается агентский договор с указанием размера затрат и методом распределения маржинального дохода (возможно использование ЭДО)</li>
                        <li>За счет инвестиций наше агентство организует ремонт в квартире</li>
                        <li>Квартира выставляется в продажу и рекламу силами нашего агентства</li>
                        <li>Денежные средства от продажи квартиры поступают на счет инвестора</li>
                        <li>На основании фактических данных о доходах и расходах подписывается соглашение о распределении маржинального дохода с последующим перечислением вознаграждения в адрес нашего агентства</li>
                    </ul>
                    <br/>
                    Если Вас заинтересовало наше предложение - <a href={'/contacts'}>напишите нам</a>
                    </Col>
                    <Col>
                    <img src={'/img/about.jpg'} alt={'About'} width={'100%'}/>
                    </Col>
                </Row>
            </Container>

        </div>

    </div>
        );
};

export default About;