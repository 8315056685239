import React from 'react';
import {Button} from "react-bootstrap";

const CFinFormSubmit = ({callback, ...props}) => {
    return (
        <div>
            <Button variant="secondary" onClick={callback}>Расчитать</Button>
        </div>
    );
};

export default CFinFormSubmit;
