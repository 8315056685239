import React from 'react';
import {STYLES} from "../App";

const About = () => {
    return (
    <div>
        <div className={STYLES.cards}>
            <h4>Информация об агентстве</h4>
            <ul>
                <li>ИП Фомичев Алексей Владимирович</li>
                <li>ОГРНИП 322665800035628</li>
            </ul>
            <h4>Свяжитесь с нами удобным для Вас способом</h4>
            <ul>
                <li>Телефон <a href={"tel:+79122493713"}>+7-912-24-93-713</a></li>
                <li>E-mail  <a href={"mailto:info@flippium.ru"}>info@flippium.ru</a></li>
            </ul>
            <h4>Наши мессенджеры</h4>
                    <a href={'https://t.me/vilebone'} target={'_blank'} rel={'noreferrer'}><img src={"/img/icons/messengers/telegram.png"} width={"32"} height={"32"}/></a>
            &nbsp;
                    <a href={'https://wa.me/79122493713?text=У меня вопрос по услугам flippium'} target={'_blank'} rel={'noreferrer'}><img src={"/img/icons/messengers/whatsapp.png"} width={"32"} height={"32"}/></a>
            </div>
    </div>
        );
};

export default About;