import React, {useRef} from 'react';
import {Form, InputGroup, Button} from "react-bootstrap";

const CInputUrl = ({value, callback, ...props}) => {

    const refInput = useRef(null)
    const onSubmit = (e) => {
        e.preventDefault()
        callback(refInput.current.value)
    }

    return (
        <div>
            <Form onSubmit={(event) => onSubmit(event)}>
                <Form.Label htmlFor={"inputUrl"}>Объявление</Form.Label>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="URL-адрес объявления"
                        aria-label="URL-адрес объявления"
                        aria-describedby="inputUrlHelp"
                        id={"inputUrl"}
                        defaultValue={value}
                        ref={refInput}
                        type={"search"}
                    />
                    <Button variant="outline-secondary" id="buttonAnalyze" onClick={() => callback(refInput.current.value)}>
                        Анализировать
                    </Button>
                </InputGroup>
                <Form.Text id={"inputUrlHelp"} muted>Введите ссылку на объявление, которое Вы хотите проанализировать</Form.Text>
            </Form>
        </div>
    );
};

export default CInputUrl;