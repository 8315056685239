import React from 'react';
import Utils from "../api/utils";

const COfferItem = ({offer, index, ...props}) => {
    return (
        <tr align={"center"}>
            <td>{index}</td>
            <td>{offer.area}</td>
            <td>{offer.rooms}
                { offer.balconies ? "+Б" : ""}</td>
            <td>{offer.floor} / {offer.floor_total}</td>
            <td>{offer.building_year}</td>
            <td>{Utils.decodeRepairType(offer.repair)}</td>
            <td>{(offer.price / Math.pow(10,6)).toFixed(1)}</td>
            <td>{Utils.getExposition(offer)}</td>
            <td><a href={offer.url} target="_blank" rel={"noreferrer"}>Ссылка</a></td>
        </tr>
    );
};

export default COfferItem;
