import React from 'react';
import {InputGroup, Form} from "react-bootstrap";

const CFinForm = ({data, callback, ...props}) => {

    const addSpaces = (num) => {
        return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "";
    }
    const removeNonNumeric = (num) => {
        return num ? num.toString().replace(/[^0-9]/g, "") : 0;
    }

    return (
        <div>
            <InputGroup className="mb-3">
                <InputGroup.Text id="capex">Цена покупки</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="руб."
                    aria-label="руб."
                    aria-describedby="capex"
                    onChange={e => callback({capex:removeNonNumeric(e.target.value)})}
                    value={addSpaces(data.capex)}
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="capex_target">Цена продажи</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="руб."
                    aria-label="руб."
                    aria-describedby="capex_target"
                    onChange={e => callback({capex_target:removeNonNumeric(e.target.value)})}
                    value={addSpaces(data.capex_target)}
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="duration">Длительность проекта</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="мес."
                    aria-label="мес."
                    aria-describedby="duration"
                    onChange={e => callback({duration:e.target.value})}
                    value={data.duration}
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="mortgage_rate">Ставка по ипотеке</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="%"
                    aria-label="%"
                    aria-describedby="mortgage_rate"
                    onChange={e => callback({mortgage_rate:e.target.value})}
                    value={data.mortgage_rate}
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="mortgage_duration">Срок ипотеки</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="лет"
                    aria-label="лет"
                    aria-describedby="mortgage_duration"
                    onChange={e => callback({mortgage_duration:e.target.value})}
                    value={data.mortgage_duration}
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="cf_down_payment">Первоначальный взнос</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="руб."
                    aria-label="руб."
                    aria-describedby="cf_down_payment"
                    onChange={e => callback({cf_down_payment:removeNonNumeric(e.target.value)*-1})}
                    value={addSpaces(data.cf_down_payment*-1)}
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="cf_repair">Затраты на ремонт</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="руб."
                    aria-label="руб."
                    aria-describedby="cf_repair"
                    onChange={e => callback({cf_repair:removeNonNumeric(e.target.value)*-1})}
                    value={addSpaces(data.cf_repair*-1)}
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="cf_mortgage">Затраты на ипотеку</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="руб."
                    aria-label="руб."
                    aria-describedby="cf_mortgage"
                    onChange={e => callback({cf_mortgage:removeNonNumeric(e.target.value)*-1})}
                    value={addSpaces(data.cf_mortgage*-1)}
                    disabled={true}
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="cf_overheads">Накладные расходы</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="руб."
                    aria-label="руб."
                    aria-describedby="cf_overheads"
                    onChange={e => callback({cf_overheads:removeNonNumeric(e.target.value)*-1})}
                    value={addSpaces(data.cf_overheads*-1)}
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="tax_rate">Налог на прибыль</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="%"
                    aria-label="%"
                    aria-describedby="tax_rate"
                    onChange={e => callback({tax_rate:e.target.value})}
                    value={data.tax_rate}
                />
            </InputGroup>
            <div>Результаты расчетов:</div>
            <div>Прибыль: {addSpaces(data.profit)}</div>
            <div>Доходность: {data.roi}%</div>


        </div>
    );
};

export default CFinForm;
