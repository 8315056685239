import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './flippium.css';
import {BrowserRouter, Navigate, Routes, Route} from "react-router-dom";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import Analyze from "./pages/Analyze";
import CNavbar from "./components/CNavbar";
import Recents from "./pages/Recents";
import Browser from "./pages/Browser";
import Cases from "./pages/Cases";
import {Container} from "react-bootstrap";

export const STYLES = {
    cards: "p-3 m-3 shadow-sm bg-white"
}

function App() {
  return (
      <div>
          <BrowserRouter>
              <CNavbar/>
              <Routes>
                  <Route path="/" element={<About/>}/>
                  <Route path="/about" element={<About/>}/>
                  <Route path="/contacts" element={<Contacts/>}/>
                  <Route path="/cases" element={<Cases/>}/>
                  <Route path="/recents" element={<Recents/>}/>
                  <Route path="/browser" element={<Browser/>}/>
                  <Route exect path="/analyze" element={<Analyze/>}/>
                  <Route path="/analyze/*" element={<Analyze/>}/>
                  <Route
                      path="*"
                      element={<Navigate to="/" replace />}
                  />
              </Routes>
          </BrowserRouter>
        <div align={"center"}>
            &copy; ИП Фомичев Алексей Владимирович, 2022-2024
        </div>
      </div>
  );
}

export default App;
