import axios from "axios";

export default class Utils {
    static async wrapAxiosRequest(config) {
        try {
            return await axios.request(config)
        } catch (error) {
            return error.response
        }
    }

    // Переводит код типа ремонта на русский язык
    static decodeRepairType(repair) {
        switch (repair) {
            case 'none':
                return 'Нет'

            case 'cosmetic':
                return 'Косметический'

            case 'euro':
                return 'Евро'

            default:
                return 'Не определен'
        }
    }

    // Возвращает срок экспозиции объявления
    static getExposition(offer) {
        const now = Date.now() / 1000
        let exposition = 0
        if (offer.ts_closed > 0) {
            exposition = offer.ts_closed - Math.max(offer.timestamp, offer.ts_created)
        } else exposition = now - Math.max(offer.timestamp, offer.ts_created)
        return Math.trunc(exposition / 60 / 60 / 24)
    }

    static addMonths(date, months) {
        /**
         * Добавляет к дате указанное количество месяцев
         * @param date {date} - Исходная дата
         * @param months {int} - Количество месяцев, которое надо прибавить к дате
         * @return {date}
         */
        let d = date.getDate();
        date.setMonth(date.getMonth() + months);
        if (date.getDate() != d) {
            date.setDate(0);
        }
        return date;
    }

    static date2str(date) {
        /**
         * Преобразует дату в строке в формате "YYYY-mm-dd"
         * @param date {date} - Дата
         * @return {string}
         */
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = date.getFullYear();

        return `${yyyy}-${mm}-${dd}`
    }
}