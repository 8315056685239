import Utils from "./utils";

const API_URL = '/api/browser'

export default class BrowserApi {
    /**
     * Создает запрос на получение объявлений из локальной базы данных
     * флиппинг-проекта на основании исходных данных
     * @param browserOptions {Object} - параметры выдаваемых объявлений
     * @returns {Promise<any|undefined>}
     */
    static async getOffers(browserOptions){
        const config = {
            method: 'post',
            url: API_URL,
            data: {
                data: {...browserOptions}
            }
        }
        return Utils.wrapAxiosRequest(config)
    }

}