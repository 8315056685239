import Utils from "./utils";

const API_OFFER_URL = '/api/offer'
const API_ADJACENT_URL = '/api/adjacent'


export default class Offer {
    /**
     * Создает запрос на получение данных объявления по его идентификатору в БД
     * @param offerId {number} - Внитренний идентификатор объявления (offer_id)
     * @returns {Promise<AxiosResponse<any>|axios.AxiosResponse<any>|*|undefined>}
     */
    static async getById(offerId) {
        const config = {
            method: 'get',
            url: API_OFFER_URL,
            params: {
                offer_id: offerId
            }
        }
        return Utils.wrapAxiosRequest(config)
    }

    /**
     * Создает запрос на получение данных объявления по его web-адресу
     * @param offerUrl {string} - URL-адрес, по которму расположено объявление
     * @returns {Promise<AxiosResponse<any>|axios.AxiosResponse<any>|*|undefined>}
     */
    static async getByUrl(offerUrl) {
        const config = {
            method: 'get',
            url: API_OFFER_URL,
            params: {
                url: offerUrl
            }
        }
        return Utils.wrapAxiosRequest(config)
    }

    /**
     * Создает запрос на получение списка объявлений, расположенных рядом с указанным объявлением
     * @param offer_id {number} - Идентификатор объявления в локальной БД flippium
     * @param force_download {boolean} - Если установлено в true, то список расположенных рядом объявлений
     * в принудительном режим загружается в реальном времени в сайтов объявлений
     * @param history_mode {boolean} - Если установлено в true, то будет загружены объявления из БД,
     * которые сейчас уже неактуальные (проданы)
     * @returns {Promise<any|undefined>}
     */
    static async getAdjacentById(offer_id, force_download = false, history_mode = false){
        const config = {
            method: 'get',
            url: API_ADJACENT_URL,
            params: {
                offer_id: offer_id,
                force_download: force_download,
                history_mode: history_mode
            }
        }

        return Utils.wrapAxiosRequest(config)
    }
}