import Utils from "./utils";

const API_RECENTS_URL = '/api/recents'


export default class RecentsList {
    /**
     * Создает запрос на получение данных о последних добавленных в БД объявлениях
     * @returns {Promise<AxiosResponse<any>|axios.AxiosResponse<any>|*|undefined>}
     */
    static async get() {
        const config = {
            method: 'get',
            url: API_RECENTS_URL,
            params: {
            }
        }
        return Utils.wrapAxiosRequest(config)
    }
}