import React from 'react';
import {Navbar, Nav, Container} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";

const CNavbar = (props) => {
    const location = useLocation()
        return (
            <Navbar bg="dark" variant={'dark'} expand="lg" className={"p-sm-0"}>
                <Container>
                    <Navbar.Brand as={Link} to="/">Flippium</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav activeKey={location.pathname.match(/(\/[^/]*\/?)/).pop()} className="me-auto">
                            <Nav.Link as={Link} to={'/about/'} eventKey={"/about/"}>О нас</Nav.Link>
                            <Nav.Link as={Link} to={'/analyze/'} eventKey={"/analyze/"}>Анализатор</Nav.Link>
                            <Nav.Link as={Link} to={'/cases/'} eventKey={"/cases/"}>Кейсы</Nav.Link>
                            <Nav.Link as={Link} to={'/contacts/'} eventKey={"/contacts/"}>Контакты</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
}

export default CNavbar;