import React from 'react';
import {InputGroup, Form, Dropdown} from "react-bootstrap";

const CBrowserOptions = ({data, callback, ...props}) => {
    const addSpaces = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    const removeNonNumeric = (num) => {
        return num.toString().replace(/[^0-9]/g, "");
    }

    return (
        <div>
            <Form.Select aria-label={'City'} className="mb-3" defaultValue={data.city} onChange={e => callback({city: e.target.value})}>
                <option value={null}>Город</option>
                <option value={'ekb'}>Екатеринбург</option>
            </Form.Select>
            Уровень ремонта:
            <Form.Check
                type={'checkbox'}
                id={'repair_undefined'}
                label={'Не определен'}
                onChange={e => callback({repair_undefined: e.target.checked})}
            />
            <Form.Check
                type={'checkbox'}
                id={'repair_none'}
                label={'Без ремонта'}
                onChange={e => callback({repair_none: e.target.checked})}
            />
            <Form.Check
                type={'checkbox'}
                id={'repair_cosmetic'}
                label={'Косметический'}
                onChange={e => callback({repair_cosmetic: e.target.checked})}
            />
            <Form.Check
                type={'checkbox'}
                id={'repair_euro'}
                label={'Евро'}
                onChange={e => callback({repair_euro: e.target.checked})}
            />
            Статус объявления:
            <Form.Check
                type={'checkbox'}
                id={'status_open'}
                label={'Продается'}
                onChange={e => callback({status_open: e.target.checked})}
            />
            <Form.Check
                type={'checkbox'}
                id={'status_created'}
                label={'Выставлен'}
                onChange={e => callback({status_created: e.target.checked})}
            />
            <Form.Check
                type={'checkbox'}
                id={'status_closed'}
                label={'Продан'}
                onChange={e => callback({status_closed: e.target.checked})}
            />

            {/*<Form.Check aria-label={'Repair'} className="mb-3" defaultValue={data.repair} onChange={e => callback({repair: e.target.value})}>*/}
            {/*    <option value={null}>Ремонт</option>*/}
            {/*    <option value={'undefined'}>Не определен</option>*/}
            {/*    <option value={'none'}>Без ремонта</option>*/}
            {/*    <option value={'cosmetic'}>Косметический</option>*/}
            {/*    <option value={'euro'}>Евро</option>*/}
            {/*</Form.Check>*/}
            <InputGroup className="mb-3">
                <InputGroup.Text id="price_start">Цена от</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="руб."
                    aria-label="руб."
                    aria-describedby="price_start"
                    onChange={e => callback({price_start:removeNonNumeric(e.target.value)})}
                    value={addSpaces(data.price_start || '')}
                />
                <InputGroup.Text id="price_end">до</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="руб."
                    aria-label="руб."
                    aria-describedby="price_end"
                    onChange={e => callback({price_end:removeNonNumeric(e.target.value)})}
                    value={addSpaces(data.price_end || '')}
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text id="area_start">Площадь от</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="м2"
                    aria-label="м2"
                    aria-describedby="area_start"
                    onChange={e => callback({area_start:removeNonNumeric(e.target.value)})}
                    value={addSpaces(data.area_start || '')}
                />
                <InputGroup.Text id="area_end">до</InputGroup.Text>
                <Form.Control
                    type={"search"}
                    placeholder="м2"
                    aria-label="м2"
                    aria-describedby="area_end"
                    onChange={e => callback({area_end:removeNonNumeric(e.target.value)})}
                    value={addSpaces(data.area_end || '')}
                />
            </InputGroup>
            <InputGroup>
                <InputGroup.Text id={"bo_text_date_start"}>Начальная дата</InputGroup.Text>
                <Form.Control
                    type={'date'}
                    value={data.date_start}
                    onChange = {e => callback({date_start: e.target.value})}
                />
                <InputGroup.Text id={"bo_text_date_end"}>Конечная дата</InputGroup.Text>
                <Form.Control
                    type={'date'}
                    value={data.date_end}
                    onChange = {e => callback({date_end: e.target.value})}
                />
            </InputGroup>
        </div>
    );
};

export default CBrowserOptions;
