import React, {useState} from 'react';
import RecentsList from "../api/recentsList";
import {STYLES} from "../App";
import COffer from "../components/COffer";

const Recents = () => {
    const [recentData, setRecentData] = useState({loaded: false, data: []})

    if(!recentData.loaded) {
        RecentsList.get().then((response) => {
            if (response.status === 200) {
                setRecentData({loaded: true, data: response.data.data})
            }
        })
    }

    return (
        <div className={STYLES.cards}>
            {
                recentData.loaded ?
                    <COffer offers={recentData.data}/>
                    :
                    <h3>Загружаю последние добавленные объявления</h3>
            }

        </div>
    );
};

export default Recents;
