import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'
import CInputUrl from "../components/CInputURL";
import COffer from "../components/COffer";
import CFinForm from "../components/CFinForm";
import BrowserApi from "../api/browserApi";
import Offer from "../api/offer";
import CFinFormSubmit from "../components/CFinFormSubmit";
import CMap from "../components/CMap";
import {STYLES} from "../App";
import CBrowserOptions from "../components/CBrowserOptions";
import CBrowserOptionsSubmit from "../components/CBrowserOptionsSubmit";
import Utils from "../api/utils";

const DEFAULT_ERROR_MESSAGE = "Произошла ошибка<br/>Пожалуйста, обновите страницу"

const Browser = () => {

    // Текущая дата для указателя конечной даты
    const today_obj = new Date();
    const today_str = Utils.date2str(today_obj)
    // Один мясяц назад для указателя начальной даты
    const month_ago_obj = Utils.addMonths(today_obj, -1)
    const month_ago_str = Utils.date2str(month_ago_obj)

    const [browserOptions, setBrowserOptions] = useState({
        date_start: month_ago_str,
        date_end: today_str,
        city: 'ekb',
        bounds: []
    })

    const [offers, setOffers] = useState([])
    const [mapState, setMapState] = useState({
        center: [56.838011, 60.597474],
        zoom: 16
    })

    const browserOptionsCallback = (newBrowserData) => {
        return setBrowserOptions({...browserOptions, ...newBrowserData})
    }

    const submitBrowserOptions = async () => {
        BrowserApi.getOffers(browserOptions).then((response) => {
            if(response.status === 200) {
                setOffers(response.data.data)
            } else {

            }
        })
    }

    const mapBoundsChangeCallback = (newBounds) => {
        setBrowserOptions({...browserOptions, bounds: newBounds})
    }

    const mapBoundsLoadCallback = (Bounds) => {
        if(Bounds) {
            setBrowserOptions({...browserOptions, bounds: Bounds})
        }
    }

    React.useEffect(() => {
        submitBrowserOptions().then()
    }, [browserOptions])

    return (
            <div className={STYLES.cards}>
                <CBrowserOptions data={browserOptions} callback={browserOptionsCallback}></CBrowserOptions>
                {/*<CBrowserOptionsSubmit callback={submitBrowserOptions}/>*/}
                <CMap items={offers} state={mapState} onBoundsChangeCallback={mapBoundsChangeCallback} onLoadCallback={mapBoundsLoadCallback}/>
                <COffer offers={offers}/>
            </div>
    );
};

export default Browser;
