import React from 'react';
import {Table} from "react-bootstrap";
import COfferItem from "./COfferItem";

const COffer = ({offers, ...props}) => {
    return (
        <div>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr align={"center"}>
                        <td>#</td>
                        <td>м2</td>
                        <td>Комнат</td>
                        <td>Этаж</td>
                        <td>Год</td>
                        <td>Ремонт</td>
                        <td>Цена</td>
                        <td>В экспозиции</td>
                        <td>Объявление</td>
                    </tr>
                </thead>
                <tbody>
                {offers.map((offer, index) =>
                    <COfferItem offer={offer} index={index+1} key={offer.offer_id}/>
                )}

                </tbody>
            </Table>
        </div>
    );
};

export default COffer;
