import Utils from "./utils";

const API_URL = '/api/fin'

export default class Fin {
    /**
     * Формирует зопрос для получения финансовых расчетов по его web-адресу
     * @param offerUrl {string} - URL-адрес объявления
     * @returns {Promise<any|undefined>}
     */
    static async getByUrl(offerUrl) {
        const config = {
            method: 'get',
            url: API_URL,
            params: {
                url: offerUrl
            }
        }
        return Utils.wrapAxiosRequest(config)
    }

    /**
     * Формирует запрос для получения финансовых расчет по его коду
     * @param offer_id {number} - Идентификатор объявления в локальной БД flippium
     * @returns {Promise<any|undefined>}
     */
    static async getById(offer_id) {
        const config = {
            method: 'get',
            url: API_URL,
            params: {
                offer_id: offer_id
            }
        }
        return Utils.wrapAxiosRequest(config)
    }

    /**
     * Создает запрос на получение результатов расчетов финансовых параметров
     * флиппинг-проекта на основании исходных данных
     * @param finParams {Object} - исходные данные для расчета параметров проекта
     * @returns {Promise<any|undefined>}
     */
    static async getCalculation(finParams){
        console.log(finParams)
        const config = {
            method: 'post',
            url: API_URL,
            data: {
                data: {...finParams}
            }
        }
        return Utils.wrapAxiosRequest(config)
    }

}