import React, {useRef} from 'react';
import {Map, Placemark, useYMaps, YMaps, ZoomControl} from "@pbe/react-yandex-maps";
import Utils from "../api/utils";

/*
Вспомогательная функция выбора цвета элемента для отображения на Яндекс.Карте
на основании типа распознанного ремонта
repair - исходные данные об объявлении, полученные от сервера (string)
mainPlacemark - выделять ли данный элемент как основной (boolean)
 */
const formPlacemarkPreset = (repair, mainPlacemark=false) => {
    // Цвет по-умолчанию (тип ремонта не распознан)
    let preset = 'islands#brownStretchyIcon'
    if (mainPlacemark) {
        preset = 'islands#blueStretchyIcon'
    } else {
        switch (repair) {
            case 'euro':
                preset = 'islands#redStretchyIcon'
                break

            case 'cosmetic':
                preset = 'islands#greenStretchyIcon'
                break

            case 'none':
                preset = 'islands#greyStretchyIcon'
                break
        }
    }
    return preset
}

/*
Вспомогательная функция формирования элемента для отображения на Яндекс.Карте
item - исходный набор данных объявления (Object)
mainPlacemark - выделять ли данный элемент как основной (boolean)
 */
const formPlacemark = (item, mainPlacemark=false, instanceRef = null) => {
    const ballonContent = `Этаж: <b>${item.floor}/${item.floor_total}</b><br>
Площадь: <b>${item.area}</b> м2<br>
Ремонт: <b>${Utils.decodeRepairType(item.repair)}</b><br>
<b><a href="${item.url}" target="_blank">Ссылка</a></b>`
    const iconContent = `${(item.price / 1000000).toFixed(1)}`
    const iconPreset = formPlacemarkPreset(item.repair, mainPlacemark)


    return (
        <Placemark
            geometry={[item.lat, item.lng]}
            properties={{balloonContent: ballonContent, iconContent: iconContent}}
            options={{preset: iconPreset}}
            modules={['geoObject.addon.balloon']}
            key={item.offer_id}
            instanceRef={instanceRef}
        />
    )

}

/*
Компонент отображения карты Яндекс со значками-объявлениями
items - массив объявлений. Первый элемент массива будет отображен как основной (Array)
 */
const CMap = ({items, onBoundsChangeCallback, onLoadCallback, ...props}) => {

    const [map, setMap] = React.useState()

    React.useEffect(() => {
        if(map?._bounds)
            if(onLoadCallback) onLoadCallback(map._bounds)
    }, [map])


    return (
        <YMaps>
            <Map onBoundsChange={
                (e) => {
                    if(onBoundsChangeCallback) {
                        onBoundsChangeCallback(e.originalEvent.newBounds)
                    }
                }}

                 {...props} width={"100%"} height={480} instanceRef={setMap/*ref => { ref && ref.behaviors.disable('scrollZoom') && (bounds = ref.getBounds()); }*/}>
                {/*Обратный порядок прорисовки, чтобы главный элемент был сверху*/}
                {
                    items.map((item, index, items) =>
                        formPlacemark(items[items.length - 1 - index],
                            !(items.length - 1 - index) ))
                }
                <ZoomControl options={{ float: "right" }} />
            </Map>
        </YMaps>
    );
};

export default CMap;
